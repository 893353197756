import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Button,
} from '@mui/material';

import {
  FormWrapper,
  notificationShow,
  LoadingOverlay,
  PasswordInput,
} from '@frontend/common';

import { activateEmail } from './actions';

import styles from './styles.module.css';

const select = (state) => ({
  passRequirements: state.staticResources.passRequirements,
});

export class ActivateEmail extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    activateEmail: PropTypes.func.isRequired,
    location: PropTypes.object
  };

  state = {
    requestID: '',
    password: '',
    loading: false,
  };

  onPasswordChangeHandler = (value) => {
    this.setState({ password: value });
  }

  formSubmitHandle = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const data = {
      guid: this.state.requestID,
      password: this.state.password,
    };

    this.setState({ loading: true });
    this.props.activateEmail(data)
      .then(() => {
        this.props.notificationShow('Email (Username) updated. Please log in.', 'success');
        this.props.history.push('/login');
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    const location = this.props.location ? this.props.location.search : window.location.href;
    const requestID = location.slice(location.indexOf('request=')).replace('request=', '');
    this.setState({ requestID });
  }

  render() {
    const { loading } = this.state;
    return (
      <FormWrapper title='Activate Email (Username)'>
        <form
          className={styles.ActivateUser_form}
          onSubmit={this.formSubmitHandle}
          data-testid='auto-ActivateEmail-submit-form'
        >
          <PasswordInput
            id='password'
            name='password'
            label='Password'
            onChange={e => this.onPasswordChangeHandler(e.target.value)}
            inputProps={{ 'data-testid': 'auto-ActivateEmail-password-input' }}
            disabled={loading}
            fullWidth
            variant='standard'
          />
          <div className={styles.ActivateUser_buttons}>
            <Button
              variant='text'
              disabled={loading}
              style={{ width: '48%' }}
              onClick={() => this.props.history.push('/login')}
              data-testid='auto-ActivateEmail-backToLogin-button'
            >
              Cancel
            </Button>
            <LoadingOverlay show={loading} width='48%'>
              <Button
                type='submit'
                variant='contained'
                disabled={loading}
                fullWidth
                data-testid='auto-ActivateEmail-submit-button'
              >
                Activate
              </Button>
            </LoadingOverlay>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  activateEmail
})(ActivateEmail));
