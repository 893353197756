import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  TextField,
  Button,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  PasswordInput,
  PasswordRequirements,
  notificationShow
} from '@frontend/common';

import { activateUser } from './actions';

import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { taxIdNumValidator, errors, alphaNumericOnlyValidator } from 'utils/helpers/formValidators';

import styles from './styles.module.css';

const select = (state) => ({
  passRequirements: state.staticResources.passRequirements,
});

export class ActivateUser extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    activateUser: PropTypes.func.isRequired,
    passRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    location: PropTypes.object
  };

  state = {
    requestId: '',
    isAgent: false,
    taxId: '',
    pin: '',
    password: '',
    passwordConfirm: '',
    passwordPassesValidation: false,
    errors: {},
    loading: false,
    initialLoading: false,
  };

  validateInput = (inputName, value) => {
    const newErrors = { ...this.state.errors };
    const { passwordPassesValidation, isAgent } = this.state;

    switch (inputName) {
      case 'taxId': {
        // only for agents their tax id is required
        const taxIdError = taxIdNumValidator(value);
        if (isAgent && taxIdError) {
          newErrors.taxId = taxIdError;
        }
        else {
          delete newErrors.taxId;
        }
        break;
      }

      case 'pin': {
        // only for agents their tax id is required
        const pinError = alphaNumericOnlyValidator(value);
        if (!isAgent && pinError) {
          newErrors.pin = pinError;
        }
        else {
          delete newErrors.pin;
        }
        break;
      }

      case 'password': {
        if (!value) {
          newErrors.password = errors.required;
        }
        else if (!passwordPassesValidation) {
          newErrors.password = 'Must meet below requirements.';
        }
        else {
          delete newErrors.password;
        }
        break;
      }

      case 'passwordPassesValidation': {
        if (value) {
          delete newErrors.password;
        }
        break;
      }

      case 'passwordConfirm': {
        if (!value) {
          newErrors.passwordConfirm = errors.required;
        }
        else if (this.state.password !== value) {
          newErrors.passwordConfirm = 'Must match password.';
        }
        else {
          delete newErrors.passwordConfirm;
        }
        break;
      }

      default: // do nothing
    }

    return newErrors;
  }

  validateAllInputs = () => {
    // validate all inputs and merge all their errors
    const { taxId, pin, password, passwordConfirm } = this.state;
    const newErrors = {
      ...this.validateInput('taxId', taxId),
      ...this.validateInput('pin', pin),
      ...this.validateInput('password', password),
      ...this.validateInput('passwordConfirm', passwordConfirm)
    };
    this.setState({ errors: newErrors });

    return isEmpty(newErrors);
  }

  onInputChange = (inputName, value) => {
    // set value and validate only the current input
    let inputValue = value;
    // for pin numbers automaticaly convert to upper case letters
    if (inputName === 'pin') {
      inputValue = inputValue.toString().toUpperCase();
    }
    this.setState({ [inputName]: inputValue, errors: this.validateInput(inputName, inputValue) });
  }

  formSubmitHandle = (e) => {
    e.preventDefault();
    if (this.validateAllInputs()) {
      this.setState({ loading: true });

      const data = {
        TaxId: this.state.taxId,
        Pin: this.state.pin,
        Guid: this.state.requestId,
        Password1: this.state.password,
        Password2: this.state.passwordConfirm,
      };

      this.setState({ loading: true });
      this.props.activateUser(data)
        .then(() => {
          this.props.notificationShow('Account created. Please log in.', 'success');
          this.props.history.push('/login');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  componentDidMount() {
    if (this.props.passRequirements.length === 0) {
      this.setState({ initialLoading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ initialLoading: false }));
    }

    const searchParams = new URLSearchParams(document.location.search);
    const requestId = searchParams.get('request');
    const isAgent = searchParams.get('agent') === 'true';

    this.setState({ requestId, isAgent });
  }

  render() {
    const { loading, initialLoading, errors, isAgent, pin } = this.state;
    return (
      <FormWrapper title='Activate Account'>
        <LoadingOverlay
          show={initialLoading}
          indicatorHeight='10px'
        >
          <form
            className={styles.ActivateUser_form}
            onSubmit={this.formSubmitHandle}
            data-testid='auto-ActivateUser-submit-form'
          >
            {isAgent ?
              <TextField
                id='taxId'
                name='taxId'
                type='text'
                label='TIN'
                onChange={e => this.onInputChange('taxId', e.target.value)}
                inputProps={{ 'data-testid': 'auto-ActivateUser-taxId-input' }}
                error={Boolean(errors.taxId)}
                helperText={errors.taxId}
                disabled={loading || initialLoading}
                fullWidth
              />
              :
              <TextField
                id='pin'
                name='pin'
                type='text'
                label='PIN'
                onChange={e => this.onInputChange('pin', e.target.value)}
                value={pin}
                error={Boolean(errors.pin)}
                helperText={errors.pin}
                disabled={loading || initialLoading}
                fullWidth
              />
            }
            <PasswordInput
              id='password'
              name='password'
              label='Password'
              autoComplete='new-password'
              onChange={e => this.onInputChange('password', e.target.value)}
              inputProps={{ 'data-testid': 'auto-ActivateUser-newPassword-input' }}
              errorText={errors.password}
              disabled={loading || initialLoading}
              fullWidth
              variant='standard'
            />
            <PasswordInput
              id='passwordConfirm'
              name='passwordConfirm'
              label='Confirm Password'
              autoComplete='new-password'
              onChange={e => this.onInputChange('passwordConfirm', e.target.value)}
              inputProps={{ 'data-testid': 'auto-ActivateUser-confirmPassword-input' }}
              errorText={errors.passwordConfirm}
              disabled={loading || initialLoading}
              fullWidth
              variant='standard'
            />
            <div className={styles.ActivateUser_passwordRequirements}>
              <PasswordRequirements
                passwordRequirements={this.props.passRequirements}
                password={this.state.password}
                onPasswordCheck={passwordPassesValidation => this.onInputChange('passwordPassesValidation', passwordPassesValidation)}
              />
            </div>
            <div className={styles.ActivateUser_buttons}>
              <Button
                variant='text'
                disabled={loading}
                style={{ width: '48%' }}
                onClick={() => this.props.history.push('/login')}
                data-testid='auto-ActivateUser-goBackToLogin-button'
              >
                Cancel
              </Button>
              <LoadingOverlay show={loading} width='48%'>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={loading || initialLoading}
                  fullWidth
                  data-testid='auto-ActivateUser-submit-button'
                >
                  Activate
                </Button>
              </LoadingOverlay>
            </div>
          </form>
        </LoadingOverlay>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  activateUser,
  passwordRequirementsGet
})(ActivateUser));
