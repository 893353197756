import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
} from '@mui/material';

import {
  PasswordInput,
  PasswordRequirements,
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import {
  changePassword,
} from './actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.css';

const select = (state) => ({
  passwordRequirements: state.staticResources.passRequirements,
});

export class ChangePassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array,
    passwordRequirementsGet: PropTypes.func.isRequired,
  };

  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    errors: [],
    formIsValid: false,
    passwordPassesValidation: false,
    loading: false,
    initialLoading: false,
  };

  clearInputs = () => {
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }, () => {
      this.passwordFormValidate();
    });
  }

  onPasswordInputChange = (inputName, newValue) => {
    this.setState({
      [inputName]: newValue
    }, () => {
      this.passwordFormValidate();
    });
  }

  passwordChangeSubmit = () => {
    const newPasswordData = {
      oldpassword: this.state.currentPassword,
      newpassword1: this.state.newPassword,
      newpassword2: this.state.newPasswordConfirm,
    };

    this.setState({ loading: true });
    this.props.changePassword(newPasswordData)
      .then(() => {
        this.props.notificationShow('Password changed.', 'success');
        this.clearInputs();
      })
      .catch(() => null)
      .finally(() => this.setState({ loading: false }));
  }

  passwordErrorTextGet = (inputName) => {
    const error = this.state.errors.find(error => error.field === inputName);
    return error ? error.message : '';
  }

  passwordFormValidate = () => {
    const errors = [];
    let isValid = false;

    if (this.state.newPasswordConfirm && this.state.newPassword !== this.state.newPasswordConfirm) {
      errors.push({ field: 'newPasswordConfirm', message: 'Passwords must match.' });
      isValid = false;
    }
    if (this.state.newPassword && !this.state.passwordPassesValidation) {
      errors.push({ field: 'newPassword', message: 'Password does not meet below criteria.' });
      isValid = false;
    }
    /* eslint-disable indent */
    if (
      this.state.currentPassword
      && this.state.newPassword
      && this.state.newPasswordConfirm
      && this.state.passwordPassesValidation
      && this.state.newPassword === this.state.newPasswordConfirm) {
      isValid = true;
    }
    /* eslint-enable indent */

    this.setState({
      formIsValid: isValid,
      errors,
    });
  }

  componentDidMount() {
    const { passwordRequirements } = this.props;
    if (passwordRequirements.length === 0) {
      this.setState({ initialLoading: true });
      this.props.passwordRequirementsGet()
        .catch(() => null)
        .finally(() => this.setState({ initialLoading: false }));
    }
  }

  render() {
    const {
      loading, initialLoading, formIsValid, currentPassword, newPassword, newPasswordConfirm,
    } = this.state;
    const formErrors = {
      currentPassword: this.passwordErrorTextGet('currentPassword'),
      newPassword: this.passwordErrorTextGet('newPassword'),
      newPasswordConfirm: this.passwordErrorTextGet('newPasswordConfirm'),
    };

    return (
      <LoadingOverlay
        show={initialLoading}
        indicatorHeight='10px'
        width='100%'
      >
        <div className={styles.ChangePassword_passwordForm}>
          <PasswordInput
            name='currentPassword'
            label='Current Password'
            value={currentPassword}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('currentPassword', e.target.value)}
            errorText={formErrors.currentPassword}
            fullWidth
            autoFocus
            variant='standard'
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-currentPassword-input' }}
          />
          <PasswordInput
            name='newPassword'
            label='New Password'
            value={newPassword}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('newPassword', e.target.value)}
            errorText={formErrors.newPassword}
            fullWidth
            variant='standard'
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-newPassword-input' }}
          />
          <PasswordInput
            name='newPasswordConfirm'
            label='Confirm New Password'
            value={newPasswordConfirm}
            disabled={loading}
            onChange={(e) => this.onPasswordInputChange('newPasswordConfirm', e.target.value)}
            errorText={formErrors.newPasswordConfirm}
            fullWidth
            variant='standard'
            inputProps={{ 'data-testid': 'auto-MyInfo-ChangePassword-confirmPassword-input' }}
          />
          <div className={styles.ChangePassword_passwordRequirements}>
            <PasswordRequirements
              password={newPassword}
              passwordRequirements={this.props.passwordRequirements}
              onPasswordCheck={passes => this.onPasswordInputChange('passwordPassesValidation', passes)}
            />
          </div>
          <div className={styles.ChangePassword_button}>
            <LoadingOverlay show={loading}>
              <Button
                type='submit'
                variant='contained'
                onClick={this.passwordChangeSubmit}
                disabled={!formIsValid || loading || initialLoading}
                data-testid='auto-MyInfo-ChangePassword-submit-button'
              >
                Change Password
              </Button>
            </LoadingOverlay>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(select, {
  changePassword,
  notificationShow,
  passwordRequirementsGet,
})(ChangePassword);
